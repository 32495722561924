/**
 *
 * ForgotPassword Page
 *
 *
 */

import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import SEO from 'components/seo';
import ForgotPassword from 'containers/Auth/ForgotPassword';
import theme from 'utils/theme';

const ForgotPasswordPage = pageProps => (
  <ThemeProvider theme={theme}>
    <Container>
      <SEO title="Forgot Password" />
      <ForgotPassword {...pageProps} />
    </Container>
  </ThemeProvider>
);

export default ForgotPasswordPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
`;
